import { useState } from 'react'
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import Stats from '../stats'
import Hero from '../hero';
import Featuremain from '../feature';
import Pricingcomp from '../pricing_component';
import Header from '../header';



const Pricing = () => {
  return (
  <>   

   <Pricingcomp/>
    
   </>

  );
};
export default Pricing;